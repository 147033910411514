import React, { useState } from "react"
import { InternalFormContainer, PhaseOne, LeftFlexCol } from "./style"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { Form } from "../Form"
import { TextInput } from "../Form/TextInput"
import { RadioInput } from "../Form/RadioInput"
import { SubmitButton } from "components/globalStyles"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { useStaticQuery, graphql } from "gatsby"

const CREATE_FEEDBACK = gql`
  mutation CreateFeedback(
    $page: String!
    $message: String!
    $userType: String!
    $initialQuestion: String!
    $initialResponse: String!
  ) {
    createFeedback(
      input: {
        clientMutationId: "Page Feedback"
        feedbackType: "page"
        page: $page
        initialQuestion: $initialQuestion
        initialResponse: $initialResponse
        useful: "n/a"
        easy: "n/a"
        foundOut: "n/a"
        fullName: "n/a"
        email: "n/a"
        phone: "n/a"
        message: $message
        userType: $userType
      }
    ) {
      data
      success
    }
  }
`

const InternalForm = ({ introText, page }) => {
  const feedbackData = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              feedbackSubmittedText
            }
          }
        }
      }
    `
  )


  const submittedText =
    feedbackData?.wpgraphql?.siteOptions?.siteSettings?.feedbackSubmittedText

  const [submitted, setSubmitted] = useState(false)
  const [initialAnswer, setInitialAnswer] = useState(null)
  const [createFeedback] = useMutation(CREATE_FEEDBACK)

  const onSubmit = values => {
    setSubmitted(true)
    console.log("submitted:", values)


    createFeedback({
      variables: {
        page: page,
        userType: values.userType,
        message: values.message.length ? values.message : "n/a",
        initialQuestion: introText,
        initialResponse: initialAnswer,
      },
    })
  }

  return (
    <InternalFormContainer>
      <FlexGrid gap={[0, 20]}>
        <FlexRow>
          <LeftFlexCol xs={12} md={4}>
            <h2>
              {initialAnswer && !submitted
                ? "Thanks. How could we improve this page?"
                : submitted
                ? `Thanks for your feedback`
                : introText}
            </h2>
            {submitted && submittedText && <p dangerouslySetInnerHTML={{ __html: submittedText }}/>}
          </LeftFlexCol>

          <FlexCol xs={12} md={8}>
            {!initialAnswer && (
              <PhaseOne>
                <button
                  onClick={() => setInitialAnswer("yes")}
                  className="underline"
                >
                  Yes
                </button>
                <button
                  onClick={() => setInitialAnswer("no")}
                  className="underline"
                >
                  No
                </button>
              </PhaseOne>
            )}
            {initialAnswer && !submitted && (
              <Form onSubmit={onSubmit}>
                <TextInput name="message" type="textarea" />
                <p className="label">Which describes you best?</p>
                <RadioInput name="userType" value="Applicant" />
                <RadioInput name="userType" value="Support worker" />
                <RadioInput name="userType" value="Health professional" />
                <RadioInput
                  name="userType"
                  value="Other"
                  error="You must be one of these...."
                  initialValue="Applicant"
                />

                <SubmitButton type="submit" label="Submit">
                  Send feedback
                </SubmitButton>
              </Form>
            )}
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </InternalFormContainer>
  )
}

export default InternalForm
