import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const ContentWrapper = styled.div`
  padding-top: 50px;

  @media ${device.desktop} {
    padding-top: 70px;
  }

  > h1,
  > h2,
  > h5,
  > h6 {
    margin-bottom: 22px;
    font-size: 24px;
    @media ${device.desktop} {
      float: left;
      margin-bottom: 37px;
      font-size: 30px;
      width: 27.33333%;
      /* height: 100%; */
    }
  }

  hr {
    margin: 55px 0;
    background: ${props => props.theme[props.color][2]};
    @media ${device.desktop} {
      margin: 70px 0;
    }
  }

  > ul {
    font-size: 18px;
    margin-bottom: 25px;
    list-style: none;
    padding-left: 15px;
    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 35px;
      margin-left: 34%;
      width: 66%;
      padding-right: 57px;
    }

    li {
      margin-bottom: 10px;
      position: relative;
      @media ${device.desktop} {
        margin-bottom: 15px;
      }
      :before {
        content: "";
        left: -15px;
        position: absolute;
        top: 10px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: black;
        @media ${device.desktop} {
          top: 12px;
        }
      }
    }
  }

  > p {
    font-size: 18px;
    margin-bottom: 25px;
    @media ${device.desktop} {
      font-size: 20px;
      margin-bottom: 35px;
      margin-left: 34%;
      width: 66%;
      padding-right: 57px;
    }
  }

  > h3 {
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 25px;
    line-height: 125%;
    @media ${device.desktop} {
      font-size: 24px;
      margin-top: 70px;
      margin-bottom: 35px;
      margin-left: 34%;
      width: 66%;
      padding-right: 57px;
    }
  }

  > h4 {
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 25px;
    line-height: 135%;

    @media ${device.desktop} {
      font-size: 20px;
      margin-top: 70px;
      margin-bottom: 35px;
      margin-left: 34%;
      width: 66%;
      padding-right: 57px;
    }
  }

  a {
    display: inline;
    position: relative;
    color: ${props => props.theme.linkColor};
    background-image: linear-gradient(to right, black, black) !important;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: 80ms;
    :hover {
      color: #796148;
      background-image: linear-gradient(to right, #796148, #796148) !important;
    }
  }

  .wp-block-image {
    margin: 30px auto;
    @media ${device.desktop} {
      margin: 50px auto;
      margin-left: 34%;
      width: 66%;
      padding-right: 80px;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  figcaption {
    margin-top: 20px;
    font-size: 18px;
  }

  span.has-inline-background {
    background-color: ${props => props.theme[props.color][1]}!important;
    padding: 3px 0;
  }
`
