import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid, FlexCol } from "components/FlexGrid"

export const InternalFormContainer = styled.div`
  padding: 70px 0;

  @media ${device.desktop} {
    padding: 90px 0;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 25px;

    @media ${device.desktop} {
      font-size: 30px;
      margin-bottom: 0;
      padding-right: 40px;
    }
  }
`

export const LeftFlexCol = styled(FlexCol)`
  flex-direction: column;

  p {
    font-size: 18px;
    @media ${device.desktop} {
      font-size: 20px;
      margin-top: 18px;
    }
  }

  a {
    display: inline;
    position: relative;
    color: ${props => props.theme.linkColor};
    background-image: linear-gradient(to right, black, black) !important;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: 80ms;
    :hover {
      color: #796148;
      background-image: linear-gradient(to right, #796148, #796148) !important;
    }
  }
`
export const PhaseOne = styled.div`
  display: flex;
  font-size: 24px;
  align-self: flex-start;
  @media ${device.desktop} {
    font-size: 30px;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    background: none;
    background-image: linear-gradient(to right, black, black) !important;
    background-position: 0 84%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    margin-right: 30px;

    @media ${device.desktop} {
      margin-right: 43px;
    }
  }
`
