import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InternalPageWrapper } from "components/globalStyles"
import { InternalHeader } from "../components/InternalHeader"
import { Content } from "components/Content"
import CTAModule from "../components/CTAModule/CTAModule"
import { InternalForm } from "../components/InternalForm"

const InternalPage = ({ pageContext }) => {
  const { title, content, pageSettings } = pageContext
  const color = pageSettings.pageTheme

  return (
    <Layout pageContext={pageContext}>
      <SEO title={title} />
      <InternalPageWrapper>
        <InternalHeader
          title={title}
          headerDescription={pageSettings.pageHeaderDescription}
          color={color}
        />
        {content && <Content color={color}>{content}</Content>}
      </InternalPageWrapper>
      {pageSettings?.ctaBlock?.title && (
        <CTAModule pageColor={color} data={pageSettings.ctaBlock} />
      )}

      {pageSettings.feedbackQuestionText && (
        <InternalForm page={title} introText={pageSettings.feedbackQuestionText} />
      )}
    </Layout>
  )
}

export default InternalPage
