import React from "react"
import { ContentWrapper } from "./style"

const Content = ({ children, color }) => {
  return (
    <ContentWrapper
      color={color}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default Content
